* {
  font-family: sans-serif;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.joi-error-message {
  color: #ef4444;
  font-size: 12px;
  margin-top: -10px;
}

.joi-error-absolute-div {
  position: relative;
}

.joi-error-absolute {
  position: absolute;
  bottom: -40px;
}

.joi-error-right {
  text-align: end;
}

.form-error {
  position: absolute;
  z-index: 200;
  top: 10px;
  right: 0%;
  margin-inline: 25%;
  animation: fadeIn 0.5s ease;
}

.not-found-page {
  background: #ffffff;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
}

.not-found-page h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.not-found-page p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.not-found-page button {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  text-decoration: none;
}

.not-found-page button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  0% {
    transform: translateY(-2vh);
  }

  100% {
    transform: translateY(0%);
  }
}

@media (max-width:430px) {
  .joi-error-right {
    text-align: start;
  }
}